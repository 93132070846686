export default {
  install: (Vue) => {
    Vue.prototype.$plsqlReadAll = (pkg, params) => {
      return Vue.prototype.$http({
        method: 'get',
        url: `/plsql/readAll/${pkg}`,
        params: params || {}
      })
    }
    Vue.prototype.$plsqlRead = (pkg, id) => {
      return Vue.prototype.$http({
        method: 'get',
        url: `/plsql/read/${pkg}/${id}`
      })
    }
    Vue.prototype.$plsqlCreate = (pkg, data) => {
      return Vue.prototype.$http({
        method: 'post',
        url: `/plsql/create/${pkg}`,
        data: data
      })
    }
    Vue.prototype.$plsqlUpdate = (pkg, data) => {
      return Vue.prototype.$http({
        method: 'put',
        url: `/plsql/update/${pkg}/${data._id}`,
        data: data
      })
    }
    Vue.prototype.$plsqlDelete = (pkg, id) => {
      return Vue.prototype.$http({
        method: 'delete',
        url: `/plsql/delete/${pkg}/${id}`
      })
    }
    Vue.prototype.$plsqlMethod = function (pkg, proc, data) {
      return Vue.prototype.$http({
        method: 'post',
        url: `/plsql/method/${pkg}/${proc}`,
        data: data
      })
    }
    Vue.prototype.$plsqlSave = function (pkg, data) {
      return data._id ? this.$plsqlUpdate(pkg, data) : this.$plsqlCreate(pkg, data)
    }
  }
}
