/*
* Plugin gestione utente
*/

import { $EventBus } from '@/eventBus'
const _intersection = require('lodash/intersection')

export default {
  install: (Vue) => {
    Vue.prototype.$user = Vue.prototype.$localStorageGet(process.env.VUE_APP_LOCALSTORAGE_USERKEY)
    /**
     * Login: setto il local storage, e la variabile globale $user
     * @param  {Object} profile: l'oggetto del profile utente
     */
    Vue.prototype.$userLogin = (profile) => {
      Vue.prototype.$localStorageSet(process.env.VUE_APP_LOCALSTORAGE_USERKEY, profile)
      Vue.prototype.$user = profile
      $EventBus.$emit('login', profile)
    }
    /**
     * Logout: rimuovo il local storage, e la variabile globale $user
     */
    Vue.prototype.$userLogout = () => {
      Vue.prototype.$localStorageRemove(process.env.VUE_APP_LOCALSTORAGE_USERKEY)
      Vue.prototype.$user = null
      $EventBus.$emit('logout')
      window.location.href = '/api/auth/logout'
    }
    /**
     * L'utente è expired
     */
    Vue.prototype.$userHasExpired = () => {
      if (!Vue.prototype.$user) {
        return false
      } else {
        return Vue.prototype.$user.expires < new Date().getTime()
      }
    }
    /**
     * L'utente è autenticato se:
     * 1) esiste su unigepass
     * 2) il token ai auth0 non è expired
     * 3) ha almeno il ruolo "authenticated" dato manualmente su auth0
     */
    Vue.prototype.$userIsAuthenticated = () => {
      try {
        return Vue.prototype.$user &&
               Vue.prototype.$user.expires > Math.round(new Date() / 1000)
      } catch (err) {
        return false
      }
    }
    /**
     * Controlla che l'utente abbia almeno uno dei ruoli richiesti
     * chi ha il suolo "admin" bypassa il controllo
     * @param  {Array} roles: i ruoli richiesti
     */
    Vue.prototype.$userHasRoles = (roles) => {
      try {
        return Vue.prototype.$user.roles.indexOf('admin') !== -1 ||
               _intersection(roles, Vue.prototype.$user.roles).length > 0
      } catch (err) {
        return false
      }
    }
    /**
     * L'utente è external
     */
    Vue.prototype.$userIsExternal = () => {
      try {
        return Vue.prototype.$user.roles.indexOf('esterni') > -1 ? Vue.prototype.$user.id : false
      } catch (err) {
        return false
      }
    }
  }
}
