/*
* Plugin gestione localstorage
* basato su Lockr (https://github.com/tsironis/lockr)
*/

import Lockr from 'lockr'
Lockr.prefix = `${process.env.VUE_APP_LOCALSTORAGE_PREFIX}_${process.env.NODE_ENV}_`

export default {
  install: (Vue) => {
    Vue.prototype.$localStorageSet = (key, value) => {
      Lockr.set(key, value)
    }
    Vue.prototype.$localStorageGet = (key) => {
      return Lockr.get(key)
    }
    Vue.prototype.$localStorageRemove = (key) => {
      Lockr.rm(key)
    }
  }
}
