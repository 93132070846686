import Vue from 'vue'
import {
  Vuetify,
  VApp,
  VNavigationDrawer,
  VFooter,
  VList,
  VMenu,
  VSubheader,
  VAlert,
  VTooltip,
  VBtn,
  VIcon,
  VGrid,
  VToolbar,
  VDivider,
  VAvatar,
  VDialog,
  VCard,
  VDatePicker,
  VProgressLinear,
  VSnackbar,
  VChip,
  VForm,
  VTextField,
  VTextarea,
  VSelect,
  VAutocomplete,
  VCombobox,
  VCheckbox,
  VPagination,
  VStepper,
  transitions,
  VSpeedDial,
  VRadioGroup
} from 'vuetify'
import 'vuetify/src/stylus/app.styl'

Vue.use(Vuetify, {
  components: {
    VApp,
    VNavigationDrawer,
    VFooter,
    VList,
    VMenu,
    VSubheader,
    VAlert,
    VTooltip,
    VBtn,
    VIcon,
    VGrid,
    VToolbar,
    VDivider,
    VAvatar,
    VDialog,
    VCard,
    VDatePicker,
    VProgressLinear,
    VSnackbar,
    VChip,
    VForm,
    VTextField,
    VTextarea,
    VSelect,
    VAutocomplete,
    VCombobox,
    VCheckbox,
    VPagination,
    VStepper,
    transitions,
    VSpeedDial,
    VRadioGroup
  },
  theme: {
    primary: process.env.VUE_APP_COLOR_PRIMARY,
    secondary: process.env.VUE_APP_COLOR_SECONDARY,
    accent: process.env.VUE_APP_COLOR_ACCENT,
    error: process.env.VUE_APP_COLOR_ERROR,
    info: process.env.VUE_APP_COLOR_INFO,
    success: process.env.VUE_APP_COLOR_SUCCESS,
    warning: process.env.VUE_APP_COLOR_WARNING
  }
})
