import Vue from 'vue'
import Router from 'vue-router'
import { $EventBus } from '@/eventBus'
import routes from '@/routes'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: __dirname,
  linkActiveClass: 'active',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: routes
})

router.beforeEach((to, from, next) => {
  if (Vue.prototype.$userHasExpired()) { // token expired
    Vue.prototype.$userLogout()
    next({ name: 'login', query: { expired: true } })
  } else if (!Vue.prototype.$userIsAuthenticated() && to.path.indexOf('login') === -1) { // non autenticato, rimanda alla login
    Vue.prototype.$userLogout()
    next({ name: 'login', query: { unauthorized: to.path !== '/' } })
  } else {
    if (Vue.prototype.$userIsExternal() && to.path === '/') { // per gli esterni no dashboard
      next({ name: 'esterni' })
    } else {
      if (to.meta.requiredRoles) { // se è loggato e la rotta richiede un ruolo, controlla che l'utente ce l’abbia
        if (!Vue.prototype.$userHasRoles(to.meta.requiredRoles)) {
          $EventBus.$emit('message', { type: 'error', text: 'Non sei autorizzato ad accedere a questa pagina' })
          next(new Error('Unauthorized'))
        } else {
          next()
        }
      } else {
        next()
      }
    }
  }
})

export default router
