import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import UserPlugin from './plugins/user'
import LocalStoragePlugin from './plugins/localStorage'
import HttpPlugin from './plugins/http'
import PlsqlPlugin from './plugins/plsql'
import FilesaverPlugin from './plugins/filesaver'
import './filters'
import _map from 'lodash/map'

Vue.config.productionTip = false
Vue.use(LocalStoragePlugin)
Vue.use(UserPlugin)
Vue.use(HttpPlugin)
Vue.use(PlsqlPlugin)
Vue.use(FilesaverPlugin)

Vue.prototype.$regNum = /^\d+$/
Vue.prototype.$regMail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

Vue.prototype.$rules = {
  maxlength: len => v => (!v || v.toString().length <= len) || `Max ${len} caratteri`,
  required: v => !!v || 'Campo obbligatorio',
  requiredOrZero: v => (!!v || v === 0) || 'Campo obbligatorio',
  ngt: len => v => (!v || v <= len) || 'Numero troppo grande',
  numeric: v => (!v || !!Vue.prototype.$regNum.test(v)) || 'Solo numeri',
  email: v => (!v || !v.length || !!Vue.prototype.$regMail.test(v)) || 'Non è una mail valida',
  isArrayOfEmails: v => {
    let valid = false
    if (Array.isArray(v)) {
      if (v.length) {
        _map(v, (val) => {
          valid = Vue.prototype.$regMail.test(val)
        })
      } else {
        valid = true
      }
    }

    return valid || 'Non è una lista di mail'
  }
}
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
