const UserLogin = () => import('@/components/UserLogin')
const UserLogout = () => import('@/components/UserLogout')
const Dashboard = () => import('@/components/Dashboard')
const Macchinari = () => import('@/components/Macchinari')
const Clienti = () => import('@/components/anagrafica/clienti/Clienti')
const Cliente = () => import('@/components/anagrafica/clienti/Cliente')
const ClienteCoordinate = () => import('@/components/anagrafica/clienti/ClienteCoordinate')
const ClienteDestinazioni = () => import('@/components/anagrafica/clienti/ClienteDestinazioni')
const ClienteLavorazioni = () => import('@/components/anagrafica/clienti/ClienteLavorazioni')
const ClienteFornitori = () => import('@/components/anagrafica/clienti/ClienteFornitori')
const ClienteVoci = () => import('@/components/anagrafica/clienti/ClienteVoci')
const ClienteOpere = () => import('@/components/anagrafica/clienti/ClienteOpere')
const TipiLavorazione = () => import('@/components/anagrafica/TipiLavorazione')
const Componenti = () => import('@/components/anagrafica/Componenti')
const Corrieri = () => import('@/components/anagrafica/Corrieri')
const Fornitori = () => import('@/components/anagrafica/Fornitori')
const Ordini = () => import('@/components/produzione/ordini/Ordini')
const Ordine = () => import('@/components/produzione/ordini/Ordine')
const OrdineComponenti = () => import('@/components/produzione/ordini/OrdineComponenti')
const OrdineDestinazioni = () => import('@/components/produzione/ordini/OrdineDestinazioni')
const OrdineLavorazioni = () => import('@/components/produzione/ordini/OrdineLavorazioni')
const Ddts = () => import('@/components/produzione/ddt/Ddts')
const Ddt = () => import('@/components/produzione/ddt/Ddt')
const DdtMerce = () => import('@/components/produzione/ddt/DdtMerce')
const DdtMail = () => import('@/components/produzione/ddt/DdtMail')
const Arrivi = () => import('@/components/produzione/arrivi/Arrivi')
const Componente = () => import('@/components/produzione/arrivi/Componente')
const Ingressi = () => import('@/components/magazzino/ingressi/Ingressi')
const Ingresso = () => import('@/components/magazzino/ingressi/Ingresso')
const IngressoLavorazione = () => import('@/components/magazzino/ingressi/IngressoLavorazione')
const MagazzinoGestione = () => import('@/components/magazzino/gestione/Bancali')
const MagazzinoPrelievi = () => import('@/components/magazzino/prelievi/Bancali')
const IngressoBancali = () => import('@/components/magazzino/ingressi/IngressoBancali')
const Ddtsm = () => import('@/components/magazzino/ddt/Ddts')
const Ddtm = () => import('@/components/magazzino/ddt/Ddt')
const DdtmMerce = () => import('@/components/magazzino/ddt/DdtMerce')
const DdtmMail = () => import('@/components/magazzino/ddt/DdtMail')
const StoricoPrelievi = () => import('@/components/magazzino/gestione/StoricoPrelievi')
const FattureProduzione = () => import('@/components/fatturazione/FattureProduzione')
const FatturaProduzione = () => import('@/components/fatturazione/FatturaProduzione')
const FattureStock = () => import('@/components/fatturazione/FattureStock')
const FatturaStock = () => import('@/components/fatturazione/FatturaStock')
const FattureLavorazione = () => import('@/components/fatturazione/FattureLavorazione')
const FatturaLavorazione = () => import('@/components/fatturazione/FatturaLavorazione')
const FattureUscita = () => import('@/components/fatturazione/FattureUscita')
const FatturaUscita = () => import('@/components/fatturazione/FatturaUscita')
const MagazzinoAzioni = () => import('@/components/magazzino/gestione/Azioni')
const MagazzinoGestionex = () => import('@/components/esterni/Bancali')
const FallbackAuth = () => import('@/components/esterni/Bancali')

const routes = [
  { path: '/auth', name: 'auth', component: FallbackAuth },
  { path: '/login', name: 'login', component: UserLogin },
  { path: '/logout', name: 'logout', component: UserLogout },
  { path: '/', name: 'dashboard', component: Dashboard, requiredRoles: ['anagrafica', 'produzione', 'magazzino', 'fatturazione'] },
  { path: '/macchinari', name: 'macchinari', component: Macchinari, requiredRoles: ['anagrafica', 'produzione', 'magazzino', 'fatturazione'] },
  { path: '/clienti', name: 'clienti', component: Clienti, requiredRoles: ['anagrafica'] },
  {
    path: '/clienti/:id',
    name: 'cliente',
    component: Cliente,
    requiredRoles: ['anagrafica'],
    children: [
      { path: 'coordinate', name: 'clienteCoordinate', component: ClienteCoordinate },
      { path: 'destinazioni', name: 'clienteDestinazioni', component: ClienteDestinazioni },
      { path: 'voci', name: 'clienteVoci', component: ClienteVoci },
      { path: 'opere', name: 'clienteOpere', component: ClienteOpere },
      { path: 'lavorazioni', name: 'clienteLavorazioni', component: ClienteLavorazioni },
      { path: 'fornitori', name: 'clienteFornitori', component: ClienteFornitori }
    ]
  },
  { path: '/tipi-lavorazione', name: 'tipiLavorazione', component: TipiLavorazione, requiredRoles: ['anagrafica'] },
  { path: '/componenti', name: 'componenti', component: Componenti, requiredRoles: ['anagrafica'] },
  { path: '/corrieri', name: 'corrieri', component: Corrieri, requiredRoles: ['anagrafica'] },
  { path: '/fornitori', name: 'fornitori', component: Fornitori, requiredRoles: ['anagrafica'] },
  { path: '/ordini', name: 'ordini', component: Ordini, requiredRoles: ['produzione'] },
  {
    path: '/ordini/:id',
    name: 'ordine',
    component: Ordine,
    requiredRoles: ['produzione'],
    children: [
      { path: 'componenti', name: 'ordineComponenti', component: OrdineComponenti },
      { path: 'destinazioni', name: 'ordineDestinazioni', component: OrdineDestinazioni },
      { path: 'fatturazione', name: 'ordineLavorazioni', component: OrdineLavorazioni }
    ]
  },
  { path: '/arrivi', name: 'arrivi', component: Arrivi, requiredRoles: ['produzione'] },
  { path: '/componente/:id', name: 'componente', component: Componente, requiredRoles: ['produzione'] },
  { path: '/ddt', name: 'ddts', component: Ddts, requiredRoles: ['produzione'] },
  { path: '/ddtMail', name: 'ddtMail', component: DdtMail, requiredRoles: ['produzione'] },
  {
    path: '/ddt/:id',
    name: 'ddt',
    component: Ddt,
    requiredRoles: ['produzione'],
    children: [
      { path: 'merce', name: 'ddtMerce', component: DdtMerce }
    ]
  },
  { path: '/ingressi', name: 'ingressi', component: Ingressi, requiredRoles: ['magazzino'] },
  {
    path: '/ingressi/:id',
    name: 'ingresso',
    component: Ingresso,
    requiredRoles: ['magazzino'],
    children: [
      { path: 'lavorazione', name: 'ingressoLavorazione', component: IngressoLavorazione },
      { path: 'bancali', name: 'ingressoBancali', component: IngressoBancali }
    ]
  },
  { path: '/gestione', name: 'gestione', component: MagazzinoGestione, requiredRoles: ['magazzino'] },
  { path: '/ddtsm', name: 'ddtsm', component: Ddtsm, requiredRoles: ['magazzino'] },
  { path: '/ddtmMail', name: 'ddtmMail', component: DdtmMail, requiredRoles: ['magazzino'] },
  { path: '/prelievi', name: 'prelievi', component: MagazzinoPrelievi, requiredRoles: ['magazzino'] },
  { path: '/azioniBancale', name: 'azioniBancale', component: MagazzinoAzioni, requiredRoles: ['magazzino'] },
  {
    path: '/ddtm/:id',
    name: 'ddtm',
    component: Ddtm,
    requiredRoles: ['magazzino'],
    children: [
      { path: 'merce', name: 'ddtmMerce', component: DdtmMerce }
    ]
  },
  { path: '/storico-prelievi/:id', name: 'storico-prelievi', component: StoricoPrelievi, requiredRoles: ['magazzino'] },
  { path: '/fatture/produzione', name: 'fattureProduzione', component: FattureProduzione, requiredRoles: ['fatturazione'] },
  {
    path: '/fatture/produzione/:id',
    name: 'fatturaProduzione',
    component: FatturaProduzione,
    requiredRoles: ['fatturazione']
  },
  { path: '/fatture/stock', name: 'fattureStock', component: FattureStock, requiredRoles: ['fatturazione'] },
  {
    path: '/fatture/stock/:id',
    name: 'fatturaStock',
    component: FatturaStock,
    requiredRoles: ['fatturazione']
  },
  { path: '/fatture/lavorazione', name: 'fattureLavorazione', component: FattureLavorazione, requiredRoles: ['fatturazione'] },
  {
    path: '/fatture/lavorazione/:id',
    name: 'fatturaLavorazione',
    component: FatturaLavorazione,
    requiredRoles: ['fatturazione']
  },
  { path: '/fatture/uscita', name: 'fattureUscita', component: FattureUscita, requiredRoles: ['fatturazione'] },
  {
    path: '/fatture/uscita/:id',
    name: 'fatturaUscita',
    component: FatturaUscita,
    requiredRoles: ['fatturazione']
  },
  { path: '/gestionex', name: 'esterni', component: MagazzinoGestionex, requiredRoles: ['esterni'] }
]

export default routes
