import Vue from 'vue'
import accounting from 'accounting-js'
Vue.filter('currency', function (value, ndec = process.env.VUE_APP_CURRENCY_PRECISION) {
  if (!value) {
    return ''
  } else {
    return accounting.formatMoney(value, {
      symbol: process.env.VUE_APP_CURRENCY_SYMBOL,
      decimal: process.env.VUE_APP_CURRENCY_DECIMAL,
      thousand: process.env.VUE_APP_CURRENCY_THOUSAND,
      precision: parseInt(ndec)
    })
  }
})
