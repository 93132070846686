/*
* Plugin chiamate http
*/

import { $EventBus } from '@/eventBus'

const axios = require('axios')

export default {
  install: (Vue) => {
    Vue.prototype.$authHeader = () => { // torna l'header della auth valorizzato con il token dell'utente
      try {
        const token = Vue.prototype.$localStorageGet(process.env.VUE_APP_LOCALSTORAGE_USERKEY).idToken
        return `Bearer ${token}`
      } catch (err) {
        return null
      }
    }
    Vue.prototype.$initClient = function () { // istanza axios
      const httpClient = axios.create({
        baseURL: '/api/'
      })
      httpClient.interceptors.request.use((request) => {
        $EventBus.$emit('loading', true)
        request.headers.authorization = this.$authHeader() // ad ogni richiesta, invia il token
        return request
      })
      httpClient.interceptors.response.use((response) => {
        // ho finito di caricare
        $EventBus.$emit('loading', false)
        return response
      }, (err) => { // finito con errore
        $EventBus.$emit('loading', false)
        let error = err.response ? JSON.parse(JSON.stringify(err.response)) : JSON.parse(JSON.stringify(err))
        console.log('💥 errore http', error)
        if (error.status === 403) {
          $EventBus.$emit('message', { type: 'error', text: 'Non sei autorizzato ad accedere a questa pagina' })
        }
        if (error.status === 500) {
          $EventBus.$emit('message', { type: 'error', text: 'Ops qualcosa è andato storto' })
        }
        return Promise.reject(error)
      })
      return httpClient
    }
    Vue.prototype.$http = Vue.prototype.$initClient()
  }
}
