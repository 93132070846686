<template>
  <v-app v-bind:style="loadingStyle">
    <v-navigation-drawer
      :clipped="$vuetify.breakpoint.lgAndUp"
      v-model="drawer"
      fixed
      app
      v-if="$userIsAuthenticated()"
    >
      <v-list dense>
        <!--menu utente -->
        <v-list-tile v-if="$user">
          <v-list-tile-action>
            <v-avatar size="26px">
              <img :src="$user.picture">
            </v-avatar>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>
              {{ $user.user_metadata.name }}
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile @click="$router.push({ name: 'logout' })">
          <v-list-tile-action>
            <v-icon>exit_to_app</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>
              Logout
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile v-if="!$userIsExternal()" @click="$router.push({ name: 'dashboard' })">
          <v-list-tile-action>
            <v-icon>dashboard</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Dashboard</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile v-if="!$userIsExternal()" @click="$router.push({ name: 'macchinari' })">
          <v-list-tile-action>
            <v-icon>settings</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Macchinari</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-divider />
        <!--/menu utente -->
        <template v-for="item in menu">
          <!--padri con figli -->
          <v-list-group
            v-if="item.children && item.visible"
            v-model="item.model"
            :key="item.text"
            :prepend-icon="item.icon">
            <v-list-tile slot="activator">
              <v-list-tile-content>
                <v-list-tile-title>{{ item.text }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile
              v-for="(child, i) in item.children"
              @click="(child.route && child.route.name != rottaCorrente) ? $router.push({ name: child.route.name, params: child.route.params }) : ''"
              :key="i">
              <v-list-tile-action>
                <v-icon></v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>{{ child.text }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list-group>
          <!-- figli senza padri -->
          <v-list-tile
            v-if="!item.children && item.visible"
            @click="item.route ? $router.push({ name: item.route.name }) : ''"
            :key="item.text">
            <v-list-tile-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ item.text }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-toolbar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      color="primary"
      dark
      app
      fixed
      v-if="$userIsAuthenticated()">
      <v-toolbar-title style="width: 300px" class="ml-0">
        <v-toolbar-side-icon @click.stop="drawer = !drawer" class="ml-0"></v-toolbar-side-icon>
        <router-link :to="{ name: 'dashboard' }">
          <span class="hidden-md-and-up">{{ titleShort }}</span>
          <span class="hidden-sm-and-down">{{ titleLong }}</span>
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="secondary" v-if="$userHasRoles(['magazzino'])" small @click="qrCode = true"><v-icon>camera_alt</v-icon> Qr code</v-btn>
      <v-chip v-if="env !=='production'" class="mr-3" small color="warning">{{ env }}</v-chip>
      <img class="logo hidden-md-and-up" :src="logoSmall" />
      <img class="logo big hidden-sm-and-down" :src="logoBig" />
    </v-toolbar>
    <v-content v-bind:style="loadingStyle">
      <router-view v-if="!qrCode" />
      <div v-if="qrCode">
        <qrcode-stream @decode="onDecode" @init="onInit" />
        <div class="text-xs-center"><v-btn color="error" class="mt-2" @click="qrCode = false">Annulla</v-btn></div>
      </div>
      <v-dialog v-model="dialog" persistent max-width="500">
        <v-card>
          <v-card-title class="headline">Bancale rilevato</v-card-title>
          <v-card-text v-if="qrCodeValue">
            <h1>{{ qrCodeValue.bancale_id }}</h1>
            <v-btn @click="undo" small class="ma-0">Chiudi e annulla</v-btn>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="secondary" small @click="alloca">Allocazione</v-btn>
            <v-btn color="secondary" small @click="info">Informazioni</v-btn>
            <v-btn color="secondary" small @click="uscita">Prelievo</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-content>
    <!-- loading indicator -->
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    ><v-card
        color="secondary"
        dark>
        <v-card-text>
          Attendere...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- messages -->
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      :top="true"
      :color="snackbar.type"
    >
      {{ snackbar.text }}
      <v-btn
        flat
        @click="snackbar.show = false"
      >
        Chiudi
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import { $EventBus } from '@/eventBus'
import routes from '@/routes'
import _find from 'lodash/find'
import { QrcodeStream } from 'vue-qrcode-reader'
export default {
  data: () => ({
    loading: null,
    qrCode: false,
    qrCodeValue: null,
    dialog: null,
    snackbar: {
      show: false,
      type: 'info',
      timeout: parseInt(process.env.VUE_APP_MESSAGES_TIMEOUT),
      text: null
    },
    logoSmall: process.env.VUE_APP_LOGO_SVG_SMALL_WHITE,
    logoBig: process.env.VUE_APP_LOGO_SVG_BIG_WHITE,
    titleShort: process.env.VUE_APP_NAME_SHORT,
    titleLong: process.env.VUE_APP_NAME_LONG,
    env: process.env.NODE_ENV,
    drawer: null,
    rottaCorrente: ''
  }),
  components: {
    QrcodeStream
  },
  watch: {
    $route (to, from) {
      this.rottaCorrente = to.name
    }
  },
  computed: {
    loadingStyle () {
      return { opacity: this.loading ? '0.7' : '1', pointerEvents: this.loading ? 'none' : 'auto' }
    },
    menu () {
      return [
        {
          icon: 'people',
          model: true,
          text: 'Anagrafica',
          visible: this.$userHasRoles(_find(routes, r => r.name === 'clienti').requiredRoles),
          children: [
            { text: 'Clienti', route: { name: 'clienti' } },
            { text: 'Tipi Lavorazione Magazzino', route: { name: 'tipiLavorazione' } },
            { text: 'Componenti', route: { name: 'componenti' } },
            { text: 'Corrieri', route: { name: 'corrieri' } },
            { text: 'Fornitori', route: { name: 'fornitori' } }
          ]
        },
        {
          icon: 'build',
          model: true,
          text: 'Produzione',
          visible: this.$userHasRoles(_find(routes, r => r.name === 'ordini').requiredRoles),
          children: [
            { text: 'Componenti', route: { name: 'arrivi' } },
            { text: 'Ordini', route: { name: 'ordini' } },
            { text: 'DDT', route: { name: 'ddts' } }
          ]
        },
        {
          icon: 'archive',
          model: true,
          text: 'Magazzino',
          visible: this.$userHasRoles(_find(routes, r => r.name === 'ingressi').requiredRoles),
          children: [
            { text: 'Ingressi', route: { name: 'ingressi' } },
            { text: 'Gestione', route: { name: 'gestione' } },
            { text: 'DDT', route: { name: 'ddtsm' } },
            { text: 'Prelievi', route: { name: 'prelievi' } }
          ]
        },
        {
          icon: 'euro_symbol',
          model: true,
          text: 'Fatturazione',
          visible: this.$userHasRoles(_find(routes, r => r.name === 'fattureProduzione').requiredRoles),
          children: [
            { text: 'Produzione', route: { name: 'fattureProduzione' } },
            { text: 'Stock', route: { name: 'fattureStock' } },
            { text: 'Lavorazione', route: { name: 'fattureLavorazione' } },
            { text: 'Uscita', route: { name: 'fattureUscita' } }
          ]
        },
        {
          icon: 'archive',
          model: true,
          text: 'Dati cliente',
          visible: this.$userHasRoles(_find(routes, r => r.name === 'esterni').requiredRoles),
          children: [
            { text: 'Magazzino', route: { name: 'esterni' } }
          ]
        }
      ]
    }
  },
  methods: {
    undo () {
      this.dialog = false
      this.qrCodeValue = this.qrCode = false
    },
    onDecode (result) {
      this.qrCodeValue = JSON.parse(result)
      this.dialog = true
    },
    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          $EventBus.$emit('message', { type: 'error', text: 'Devi dare accesso alla fotocamera' })
        } else if (error.name === 'NotFoundError') {
          $EventBus.$emit('message', { type: 'error', text: 'Non hai una fotoamera :)' })
        } else if (error.name === 'NotSupportedError') {
          $EventBus.$emit('message', { type: 'error', text: 'Funzionalità non supportata' })
        } else if (error.name === 'NotReadableError') {
          $EventBus.$emit('message', { type: 'error', text: 'La fotocamera è già in uso?' })
        } else if (error.name === 'OverconstrainedError') {
          $EventBus.$emit('message', { type: 'error', text: 'Ops qualcosa è andato storto' })
        } else if (error.name === 'StreamApiNotSupportedError') {
          $EventBus.$emit('message', { type: 'error', text: 'Device non supportato' })
        }
      }
    },
    info () {
      this.qrCode = this.dialog = false
      this.$router.push({ name: 'azioniBancale', query: { azione: 'info', bancale: this.qrCodeValue.bancale_id } })
    },
    alloca () {
      this.qrCode = this.dialog = false
      this.$router.push({ name: 'azioniBancale', query: { azione: 'alloca', bancale: this.qrCodeValue.bancale_id } })
    },
    uscita () {
      this.qrCode = this.dialog = false
      this.$router.push({ name: 'azioniBancale', query: { azione: 'uscita', bancale: this.qrCodeValue.bancale_id } })
    }
  },
  mounted () {
    $EventBus.$on('loading', (payload) => {
      this.loading = payload
    })
    $EventBus.$on('message', (payload) => {
      payload.show = true
      this.snackbar = payload
    })
  },
  beforeDestroy () {
    console.log('!!!!')
  }
}
</script>

<style>
  .v-stepper,
  .v-stepper__header {
    box-shadow: none;
  }
  .strike { text-decoration: line-through; }
  .strike label { text-decoration: line-through; }
</style>

<style scoped>
  .v-toolbar__title a {
    color: white;
    text-decoration: none;
  }
  .logo {
    width: 40px;
    margin-right: 16px;
  }
  .logo.big {
    width: 130px;
    padding-right: 0;
    margin-right: 0;
  }
</style>
