/*
* Plugin salvataggio blob con apertura in una nuova tab
* richiede adblocker disabilitato!!
*/

export default {
  install: (Vue) => {
    Vue.prototype.$openFileNewTab = (blob, fileName) => {
      const url = URL.createObjectURL(blob)
      let a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'
      a.href = url
      a.target = '_blank'
      if (fileName) a.download = fileName
      a.click()
    }
  }
}
